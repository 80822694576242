const packageJson = require('../../../package.json');

export const environment = {
  appName: 'eck',
  envName: 'DEV',
  production: false,
  test: false,
  i18nPrefix: '',
  versions: {
    app: packageJson.version,
    angular: packageJson.dependencies['@angular/core'],
    rxjs: packageJson.dependencies.rxjs,
    ngxtranslate: packageJson.dependencies['@ngx-translate/core'],
    fontAwesome: packageJson.dependencies['@fortawesome/fontawesome-free'],
    angularCli: packageJson.devDependencies['@angular/cli'],
    typescript: packageJson.devDependencies['typescript'],
    cypress: packageJson.devDependencies['cypress'],
    eslint: packageJson.devDependencies['eslint']
  },
  apiConfigs: {
    API_URL: 'https://api.dev.tktspace.co',
    AUTH_URL: 'https://auth.dev.tktspace.co',
    IMAGE_HOST: 'https://files.tktspace.co/minio',
    WEB_CLIENT_URL: 'https://events.dev.tktspace.co'
  },
};

{
  "name": "tktspace-business",
  "version": "0.0.0",
  "author": "Viacheslav Klavdiiev",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "start:dev": "ng serve --configuration development",
    "build": "ng build",
    "build:prod": "ng build --configuration production",
    "build:dev": "ng build --configuration development",
    "watch": "ng build --watch --configuration development",
    "test": "ng test",
    "i18n-update": "gs-i18n-json update",
    "generate:api": "ng-openapi-gen -c tools/gen/api-gateway.json",
    "generate:auth": "ng-openapi-gen -c tools/gen/api-auth.json",
    "format": "prettier --write \"client/**/*.ts\""
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^19.0.1",
    "@angular/common": "^19.0.1",
    "@angular/compiler": "^19.0.1",
    "@angular/core": "^19.0.1",
    "@angular/forms": "^19.0.1",
    "@angular/platform-browser": "^19.0.1",
    "@angular/platform-browser-dynamic": "^19.0.1",
    "@angular/platform-server": "^19.0.1",
    "@angular/router": "^19.0.1",
    "@angular/ssr": "^19.0.2",
    "@ng-web-apis/common": "^4.11.1",
    "@ng-web-apis/universal": "^3.0.7",
    "@ngx-translate/core": "^16.0.3",
    "@ngx-translate/http-loader": "^16.0.0",
    "@taiga-ui/addon-charts": "^4.29.0",
    "@taiga-ui/addon-commerce": "^4.29.0",
    "@taiga-ui/addon-doc": "^4.29.0",
    "@taiga-ui/addon-mobile": "^4.29.0",
    "@taiga-ui/addon-table": "^4.29.0",
    "@taiga-ui/cdk": "^4.29.0",
    "@taiga-ui/core": "^4.29.0",
    "@taiga-ui/dompurify": "^4.1.7",
    "@taiga-ui/event-plugins": "^4.3.1",
    "@taiga-ui/icons": "^4.29.0",
    "@taiga-ui/kit": "^4.29.0",
    "@taiga-ui/layout": "^4.29.0",
    "@taiga-ui/legacy": "4.29.0",
    "@taiga-ui/styles": "^4.29.0",
    "express": "^4.18.2",
    "rxjs": "~7.8.0",
    "tslib": "^2.8.1",
    "zone.js": "~0.15.0"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^19.0.2",
    "@angular/cli": "^19.0.2",
    "@angular/compiler-cli": "^19.0.1",
    "@types/dompurify": "3.0.5",
    "@types/express": "^4.17.17",
    "@types/jasmine": "~5.1.0",
    "@types/node": "^18.19.26",
    "autoprefixer": "^10.4.20",
    "jasmine-core": "~5.1.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "ng-openapi-gen": "^0.51.0",
    "postcss": "^8.4.49",
    "prettier": "^3.3.3",
    "tailwindcss": "^3.4.15",
    "typescript": "~5.5.3"
  }
}
